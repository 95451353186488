<template>
  <div class="applicationVerify">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->

        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-select
          clearable
          :options="newAuditStatusOptions"
          v-model="searchParam.auditStatus"
          label="审核状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="审核"
          v-if="scope.row.auditStatus == 1"
          type="text"
          @click="verify(scope.row.id)"
        />
        <v-button text="编辑" type="text" @click="toEdit(scope.row.id)" />
      </template>
    </v-list>
    <v-dialog
      title="提示"
      v-model="isdialog"
      @cancel="cancel"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="确定"
    >
      <div class="item">
        <v-select
          :options="verifyAuditStatus"
          v-model="form.auditStatus"
          label="审核状态"
        />
      </div>

      <div class="item">
        <v-input
          type="textarea"
          placeholder="请输入审核备注"
          v-model="form.auditRemarks"
          :width="250"
          label="审核备注"
        ></v-input>
      </div>
      <div class="info">注：请核验该应用的适用社区范围！</div>
    </v-dialog>
  </div>
</template>

<script>
import { applicationVerifyUrl, getApplicationVerifyUrl } from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import {
  newAuditStatusOptions,
  isSetOps,
  auditStatusMap,
  auditStatusOptions,
  verifyAuditStatus,
  verifyAuditStatusMap,
  pagesType,
  pagesTypeMap,
  inType,
  inTypeMap,
  isShowHomeTypeMap,
  isShowHomeType,
} from "./map";

export default {
  name: "subscribeVerify",
  data() {
    return {
      isSetOps,
      isdialog: false,
      newAuditStatusOptions,
      verifyAuditStatus,
      id: "",
      auditStatusOptions,
      searchParam: {
        validStatus: 1,
        auditStatus: "",
      },
      form: {
        auditStatus: 2,
        auditRemarks: "",
      },
      communitList: [],
      tableUrl: getApplicationVerifyUrl,
      headers: [
        {
          prop: "modelTitle",
          label: "应用名称",
        },
        {
          prop: "modelDesc",
          label: "应用描述",
        },
        {
          prop: "modelIconPic",
          label: "应用图标",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "targetType",
          label: "应用类型",
          formatter: (row, prop) => pagesTypeMap[row.targetType],
        },
        {
          prop: "sourceType",
          label: "入口类型",
          formatter: (row, prop) => inTypeMap[row.sourceType],
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          formatter: (row, prop) => auditStatusMap[row.auditStatus],
        },
        {
          prop: "sortIndex",
          label: "排序",
        },
        {
          prop: "targetTenantNames",
          label: "适用社区",
          formatter: (row, prop) => {
            if (row.targetTenantNames) {
              return row.targetTenantNames.join(",");
            } else {
              return "--";
            }
          },
          showOverflowTooltip: true,
        },
        {
          prop: "auditRemarks",
          label: "审核备注",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    toEdit(id) {
      this.$router.push({
        name: "applicationVerifyForm",
        query: {
          id: id,
        },
      });
    },
    verify(id) {
      this.isdialog = true;
      this.id = id;
    },
    cancel() {
      this.isdialog = false;
    },
    confirm() {
      this.postVerify();
    },
    postVerify() {
      let params = {
        id: this.id,
        auditStatus: this.form.auditStatus,
        auditRemarks: this.form.auditRemarks,
      };
      this.$axios
        .post(`${applicationVerifyUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.isdialog = false;
            this.$refs.list.search();
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.applicationVerify {
  box-sizing: border-box;
  height: 100%;
  .item {
    margin: 10px 0;
    /deep/ .v-input {
      display: flex;
      align-items: center;
    }
  }
  .info {
    margin: 20px 0 0;
    color: red;
  }
}
</style>
