//订阅接口列表
const getSubscribeListUrl = `/gateway/hc-portal/open/manage/page`;
//订阅接口详情
const subscribeDetailUrl = `/gateway/hc-portal/open/detail`;
//删除接口列表
const delSubscribeListUrl = `/gateway/hc-portal/open/manage/remove`;
//新增接口
const addSubscribeListUrl = `/gateway/hc-portal/open/manage/submit`;
//获取订阅审核列表
const getSubscribeVerifyUrl = `/gateway/hc-portal/registerInfo/manage/page`;
//订阅信息修改
const editVerifyUrl = `/gateway/hc-portal/registerInfo/manage/edit`;
//审核订阅列表
const subscribeVerifyUrl = `/gateway/hc-portal/registerInfo/manage/audit`;
//获取第三方应用审核列表
const getApplicationVerifyUrl = `/gateway/hc-portal/developer/manage/page`;
//审核第三方应用   /hc-portal/open/detail
const applicationVerifyUrl = `/gateway/hc-portal/developer/manage/audit`;
//开发者账号列表
const developerUrl = `/gateway/hc-portal/client/manage/page`;
//删除开发者账号
const delDeveloperUrl = `/gateway/hc-portal/client/manage/remove`;
//开发者账号审核
const handleDeveloperUrl = `/gateway/hc-portal/client/manage/audit`;
//社区列表
const tenantListUrl = `/gateway/hc-portal/client/tenantListAll`;
//添加社区账号
const addClientUrl = `/gateway/hc-portal/client/manage/addClient`;
//应用新增或者修改
const deveAddApplyUrl = `/gateway/hc-portal/developer/manage/edit`;
//应用详情
const appDetailUrl = `/gateway/hc-portal/developer/info`;
//获取所有埋点类型列表【不分页】
const getBuriedPointUrl = `/gateway/blade-system/mng/platformModelSetting/buriedPointTypeList`;
//分类接口
const kindsListUrl = `/gateway/blade-system/mng/platformModelSetting/modelCategoryList`;
//调用次数扩展申请列表
const authApplyUrl = `/gateway/hc-portal/registerInfo/manage/applyList`;
//调用次数审核
const judgeApplyUrl = `/gateway/hc-portal/registerInfo/manage/applyAudit`;
export {
  editVerifyUrl,
  getSubscribeVerifyUrl,
  subscribeVerifyUrl,
  applicationVerifyUrl,
  getApplicationVerifyUrl,
  getSubscribeListUrl,
  delSubscribeListUrl,
  addSubscribeListUrl,
  subscribeDetailUrl,
  developerUrl,
  delDeveloperUrl,
  handleDeveloperUrl,
  tenantListUrl,
  addClientUrl,
  deveAddApplyUrl,
  appDetailUrl,
  getBuriedPointUrl,
  kindsListUrl,
  authApplyUrl,
  judgeApplyUrl,
};
